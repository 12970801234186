import { ComponentProps } from "preact";
import {
  Dispatch,
  StateUpdater,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "preact/hooks";
import { XF } from "../../../XF";
import {
  gtmCategoriesApply,
  gtmCategoriesCategory,
  gtmCategoriesClear,
} from "../../../common/gtm-ids";
import { partition } from "../../../common/partition";
import { getCategories } from "../../services/feed-content";
import { Loader } from "../Loader/Loader";
import { ShortNumber } from "../ShortNumber/ShortNumber";

export function SummariesCategories({
  categories,
  setCategories,
  selected,
  clearCategories,
  applyCategories,
}: {
  categories: Awaited<ReturnType<typeof getCategories>> | undefined;
  setCategories: Dispatch<
    StateUpdater<Awaited<ReturnType<typeof getCategories>> | undefined>
  >;
  selected: string[];
  clearCategories: () => void;
  applyCategories: (categories: string[]) => void;
}) {
  const refAsync = useRef(0);
  const [state, setState] = useState<"loading" | "loaded" | "error">(
    categories?.nodes.length ? "loaded" : "loading"
  );

  const [internalSelected, setInternalSelected] = useState(selected);
  useEffect(() => {
    setInternalSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (categories?.nodes.length) return;
    setState("loading");
    (async () => {
      const current = ++refAsync.current;
      try {
        const data = await getCategories();
        if (refAsync.current !== current) return;
        setCategories(data);
        setState("loaded");
      } catch (err) {
        if (refAsync.current !== current) return;
        setState("error");
        console.error("Failed to load categories", err);
      }
    })();
  }, [categories?.nodes.length, setCategories]);

  const [categoriesFollowing, categoriesNormal] = useMemo(
    () => partition(categories?.nodes || [], (i) => !!i.followed),
    [categories]
  );
  const isFollowingSelected = useMemo(
    () => internalSelected.includes("following"),
    [internalSelected]
  );

  // switch from "following" to individually selecting all followed nodes
  useEffect(() => {
    if (
      isFollowingSelected &&
      categoriesFollowing.some((i) => internalSelected.includes(i.id))
    ) {
      setInternalSelected((s) => {
        const followingIds = categoriesFollowing.map((i) => i.id);
        const selectedFollowing = s.filter((i) => followingIds.includes(i));
        return s
          .filter((i) => i !== "following" && !selectedFollowing.includes(i))
          .concat(followingIds.filter((i) => !selectedFollowing.includes(i)));
      });
    }
  }, [isFollowingSelected, internalSelected, categoriesFollowing]);
  // switch from individually selected all followed nodes to "following"
  useEffect(() => {
    if (
      !isFollowingSelected &&
      categoriesFollowing.every((i) => internalSelected.includes(i.id))
    ) {
      setInternalSelected((s) =>
        s
          .filter((i) => !categoriesFollowing.some((j) => j.id === i))
          .concat("following")
      );
    }
  }, [isFollowingSelected, internalSelected, categoriesFollowing]);

  const onClickCategory = useCallback<
    NonNullable<ComponentProps<"input">["onChange"]>
  >((event) => {
    const id = event.currentTarget.dataset.value;
    if (!id) return;
    setInternalSelected((s) => {
      if (s.includes(id)) return s.filter((i) => i !== id);
      return s.concat(id);
    });
  }, []);
  const onApply = useCallback(
    () => applyCategories(internalSelected),
    [applyCategories, internalSelected]
  );

  return (
    <>
      {state === "loading" && <Loader />}
      {state === "error" &&
        XF.phrase("oops_we_ran_into_some_problems_more_details_console")}
      {categoriesFollowing.length > 0 && (
        <>
          <h3>Followed</h3>
          <ol class="categories">
            {categoriesFollowing.map((i) => (
              <li key={i.id}>
                <label data-gtm={gtmCategoriesCategory} data-gtm-context={i.id}>
                  <input
                    type="checkbox"
                    checked={
                      isFollowingSelected || internalSelected.includes(i.id)
                    }
                    onChange={onClickCategory}
                    data-value={i.id}
                  />
                  {i.displayName} (<ShortNumber number={i.count} />)
                </label>
              </li>
            ))}
          </ol>
        </>
      )}
      {categoriesNormal.length > 0 && (
        <>
          <h3>Suggestions</h3>
          <ol class="categories">
            {categoriesNormal.map((i) => (
              <li key={i.id}>
                <label data-gtm={gtmCategoriesCategory} data-gtm-context={i.id}>
                  <input
                    type="checkbox"
                    checked={internalSelected.includes(i.id)}
                    onChange={onClickCategory}
                    data-value={i.id}
                  />
                  {i.displayName} (<ShortNumber number={i.count} />)
                </label>
              </li>
            ))}
          </ol>
        </>
      )}
      <footer>
        <button
          type="button"
          class="button button--outlined"
          onClick={clearCategories}
          data-gtm={gtmCategoriesClear}
        >
          Clear all
        </button>
        <button
          type="button"
          class="button button--primary"
          onClick={onApply}
          data-gtm={gtmCategoriesApply}
          data-gtm-context={internalSelected.join(",")}
        >
          Apply
        </button>
      </footer>
    </>
  );
}
