import clsx from "clsx";
import { ComponentProps, Key } from "preact";
import { useCallback, useMemo, useState } from "preact/hooks";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import { SummaryBase } from "./SummaryBase";
import { SummaryMention } from "./SummaryMention";

export function SummaryMentionGroups({
  summary,
  groups,
  gtmGroup,
}: ComponentProps<typeof SummaryBase> & {
  summary: ComponentProps<typeof SummaryBase>["summary"] & {
    node: { __typename: "DiscoveryV4BrandStory" | "DiscoveryV4PollStory" };
  };
  groups: {
    key: Key;
    title: string;
    highlight?: string;
    mentions: ComponentProps<typeof SummaryMention>["post"][];
  }[];
  /** gtm id for group buttons */
  gtmGroup: string;
}) {
  const [selected, setSelected] = useState(() =>
    groups.findIndex((i) => i.mentions.length)
  );
  const [seeAll, setSeeAll] = useState(false);
  const onClickGroup = useCallback<
    NonNullable<ComponentProps<"button">["onClick"]>
  >((event) => {
    const newS = parseInt(event.currentTarget.value, 10);
    setSelected((s) => (s === newS ? -1 : newS));
    setSeeAll(false);
  }, []);
  const onClickSeeAll = useCallback(() => {
    setSeeAll(true);
  }, []);
  const selectedGroup = groups[selected];
  const mentions = useMemo(
    () => selectedGroup?.mentions.slice().reverse(),
    [selectedGroup]
  );
  return (
    <>
      <HorizontalScroller Tag="ul" class="summary-mention-groups">
        {groups.map((i, idx) => (
          <li key={i.key}>
            <button
              type="button"
              class={clsx("button button--outlined", {
                selected: idx === selected,
              })}
              value={idx}
              onClick={onClickGroup}
              data-gtm={gtmGroup}
              data-gtm-context={i.key}
              title={i.title}
              disabled={!i.mentions.length}
            >
              <div class="summary-mention-group-title">{i.title}</div>
              {i.mentions.length ? (
                <>
                  {i.mentions.length} mention
                  {i.mentions.length === 1 ? "" : "s"}
                </>
              ) : (
                "No mentions"
              )}
            </button>
          </li>
        ))}
      </HorizontalScroller>
      {selected >= 0 && mentions.length > 0 && (
        <>
          <ul class="summary-mentions">
            {(seeAll ? mentions : mentions.slice(0, 1)).map((i) => (
              <li key={i.id} class="summary-mention">
                <SummaryMention
                  post={i}
                  highlight={selectedGroup.highlight ?? selectedGroup.title}
                  gtmContext={{ storyId: summary.node.id }}
                />
              </li>
            ))}
          </ul>
          {!seeAll && mentions.length > 1 && (
            <button
              type="button"
              class="button button--outlined summary-seeallmentions"
              onClick={onClickSeeAll}
            >
              See {mentions.length - 1} more&nbsp;
              <i class="fa fa-caret-down" />
            </button>
          )}
        </>
      )}
    </>
  );
}
