// Must be the first import
import "./common/preact-debug";

import { render } from "preact";
import { Home } from "./imagefeed/components/Home/Home";

const elHome = document.getElementById("home-container-body");

if (elHome) {
  $(() => {
    render(
      <Home
        summaries={
          elHome.dataset.summaries === "true" &&
          window.location.pathname === "/"
        }
        summariesIsDefault={elHome.dataset.summariesIsDefault === "true"}
        polls={elHome.dataset.summariesPolls === "true"}
        numFollowed={parseInt(elHome.dataset.numFollowed || "0", 10)}
        useHtmlFeed={elHome.dataset.useHtmlFeed === "true"}
      />,
      elHome
    );
  });
}
